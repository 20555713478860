<template>
	<div class="login">
		<el-row class="el-row">
			<el-col :span="16" class="el-colss">
				<el-row class="el-row el-rows">
					<el-col :span="10" class="el-cols">
						<div class="cenformbox">
							<h1>湖南12320转运车管理中心</h1>
							<el-form :model="form" ref="Loginrulesref" :rules="Loginrules">
								<el-form-item prop="userName">
									<el-input v-model="form.userName" prefix-icon="el-icon-user-solid"></el-input>
								</el-form-item>
								<el-form-item prop="passwd">
									<el-input class="el-input" type="password" v-model="form.passwd" show-passwd
										prefix-icon="el-icon-lock"></el-input>
								</el-form-item>
								<!-- <el-form-item prop="code" class="yzmitem">
                  <el-input
                    class="el-input"
                    type="text"
                    v-model="form.code"
                    prefix-icon="el-icon-mobile"
                  ></el-input>
                  <img src="../assets/logoimg.png" class="img" />
                </el-form-item>-->
								<el-form-item>
									<el-button class="el-button" type="primary" @click="logintj">提交1111</el-button>
								</el-form-item>
							</el-form>
						</div>
					</el-col>
					<el-col :span="14" class="el-cols">
						<img src="../assets/logoimg.png" class="img" @click="getcodeimg" />
						<img />
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>
<script>
	// import { async } from "q";
	// import { log } from "util";
	export default {
		name: "login",
		data() {
			return {
				form: {
					userName: "18182129855",
					passwd: "123456",
					// code: ""
				},
				Loginrules: {
					userName: [{
							required: true,
							message: "请输入用户名",
							trigger: "blur"
						},
						{
							min: 2,
							max: 16,
							message: "长度在 2 到 16 个字符",
							trigger: "blur"
						}
					],
					passwd: [{
							required: true,
							message: "请输入密码",
							trigger: "blur"
						},
						{
							min: 3,
							max: 18,
							message: "长度在 3 到 18 个字符",
							trigger: "blur"
						}
					]
					// ,
					// code: [
					//   { required: true, message: "请输入验证码", trigger: "blur" },
					//   {
					//     min: 4,
					//     max: 4,
					//     message: "验证码为4位数字或字母拼接",
					//     trigger: "blur"
					//   }
					// ]
				}
			};
		},

		methods: {
			getcodeimg() {

			},
			logintj() {
				this.$refs.Loginrulesref.validate(async valid => {
					if (!valid) return
					const res = await this.$http.post(
						'car-gateway-server/account/user/login',
						JSON.parse(JSON.stringify(this.form)))
					console.log(res.data.data);
					// if (res.data.result === "true") {
					//   this.$message.success("登录成功")
					//   sessionStorage.setItem("userId", res.data.data.user.uuid);
					//   sessionStorage.setItem("token", res.data.data.token);
					//   this.$router.push("/home");
					// } else {
					//   this.$message.error('登录失败');
					// }
				});
			}
		}
	};
</script>
<style lang="less" scoped>
	.login {
		background-image: url('../assets/img_login_page_bg@2x.jpg');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 100%;
		height: 100%;

		.el-row {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;

			.el-colss {
				height: 100%;
				box-sizing: border-box;

				.el-rows {
					display: flex;
					align-items: center;

					.el-cols {
						height: 500px;

						.cenformbox {
							height: 100%;
							background-color: #fff;
							box-sizing: border-box;
							padding: 60px 40px;

							h1 {
								text-align: center;
								color: #409eff;
							}

							.el-form-item {
								width: 100%;
								margin-top: 40px;
							}

							.el-input {
								width: 100%;
							}

							.yzmitem {
								.el-input {
									width: 50%;
								}

								.img {
									width: 100px;
									height: 38px;
									border: 1px solid #0097fe;
									float: right;
									margin-top: 2px;
								}
							}

							.el-button {
								width: 100%;
								height: 60px;
								margin-top: 20px;
								background: linear-gradient(90deg, #5798ff, #0094f9);
								box-shadow: 0px 6px 15px 0px rgba(98, 190, 254, 0.77);
								border-radius: 10px;
							}
						}

						.img {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}
	}
</style>
